module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"ja","htmlAttributes":{"prefix":"og: https://ogp.me/ns#"},"title":"iPaS｜パレット調達支援システム","description":"iPaSはパレットの荷姿構想から調達までの一連のプロセスを支援し一元管理できるシステムです。パレット調達の業務負荷を軽減し、荷姿品質の向上と調達コストの削減を実現します。","canonical":"https://ipas-dx.com/","metaTags":[{"rel":"icon","href":"/favicon.ico"}],"openGraph":{"type":"website","locale":"ja_JP","url":"https://ipas-dx.com/","site_name":"iPaS｜パレット調達支援システム","images":[{"url":"https://ipas-dx.com/og-image.jpg","width":2400,"height":1260}]},"twitter":{"cardType":"summary_large_image"},"noindex":false,"nofollow":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"98067161841de6b06d90674b3da99830"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KL4Z2XZ","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
